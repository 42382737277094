<template>
  <div class="arlist">
    <h3 class="mt-4 mb-4">Annual Report List</h3>
    <b-container class="pr-0 mb-3">
      <b-row class="">
        <b-col>{{ ar_count }} results</b-col>
        <b-col class="text-right">
          <b-button variant="primary" @click="fetchARList">
            <span v-if="table_is_busy">
              <b-spinner small v-if="table_is_busy" variant="light" label="Loading">
              </b-spinner>
              Reloading
            </span>
            <span v-if="!table_is_busy">Reload Data</span>
          </b-button>
        </b-col>
      </b-row>
    </b-container>
    <b-table striped hover :busy.sync="table_is_busy" :items="ar_list" :fields=fields>
      <template #cell(pws_id)="data">
        <a :href="`#${data.value}`">{{ data.value }}</a>
      </template>
    </b-table>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: "AnnualReportList",
  created() {
    this.fetchARList()
  },
  data() {
    return {
      ar_list: [],
      table_is_busy: false,
      fields: [
        { key: "pws_id", label: "PWS ID", sortable: true },
        { key: "pws_name", label: "PWS Name", sortable: true },
        { key: "pws_type", label: "PWS Type", sortable: true },
        { key: "data_signame", label: "Signer", sortable: true },
        { key: "data_signature_ts", label: "DateTime Signed", sortable: true },
      ]
    }
  },
  computed: {
    ar_count: function() {
      return this.ar_list.length
    }
  },
  methods: {
    reviewLink(value) {
      return value
    },
    fetchARList() {
      this.table_is_busy = true
      const path = process.env.VUE_APP_API_URI + "/annual-report-list"
      this.axios
        .get(path)
        .then((res) => {
          if (res.data) {
            // save dataset
            const ar_list = []
            res.data.forEach(function (ar_item) {
              ar_item.data_signature_ts = ar_item.data_signature_ts.replace('T', ' ')
              ar_list.push(ar_item)
            })
            this.ar_list = ar_list
            this.table_is_busy = false
          }
        })
        .catch((error) => {
          this.table_is_busy = false
          console.error(error)
        })
    }
  }
}
</script>
